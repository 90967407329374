// This module contains all React component imports and value setters
import {
	// usage reports
	GroupUsageReportWithProvider,
	OrganizationUsageReportWithProvider,
	StudentUsageReportWithProvider,
	UsageGroupsPredictedEoyWithProvider,
	UsageOrganizationsPredictedEoyWithProvider,
	// growth
	BenchmarkTestGainsReportWithProvider,
	BenchmarkTestGainsGroupLevelWithProvider,
	ReadingLevelAssessmentGainsReportWithProvider,
	ReadingLevelAssessmentGroupReportWithProvider,
	// dashboard
	UsageKpiWithProvider,
	GrowthKpiWithProvider,
	ProgressKpiWithProvider,
	AchievementsKpiWithProvider,
	StudentDashboardReportWithProvider,
	StudentDashboardWithProvider,
	DashboardGroupTableWithProvider,
	DashboardGroupsTableWithProvider,
	DashboardOrganizationsTableWithProvider,
	SidePanelWithProvider,
	GeneratePDFModal,
	// playlist
	PlaylistListReportWithProvider,
	PlaylistReportWithProvider,
	// portfolio
	GroupPortfolioWithProvider,
	StudentPortfolioWithProvider,
	// progress
	KindergartenReadinessWithProvider,
	StudentKindergartenReadinessWithProvider,
	OrganizationProgressReportNoTabsWithProvider,
	CumulativePerformanceReportWithProvider,
	SkillsInventoryReportWithProvider,
	GroupProgressByLessonReportWithProvider,
	StudentProgressByLessonReportWithProvider,
	AchievementsProgressWithProvider,
	GroupAchievementsWithProvider,
	StudentAchievementsReportWithProvider,
	// growth
	RLAStatusWithProvider,
	OrganizationBenchmarkTestPerformanceReportWithProvider,
	BenchmarkTestStatusWithProvider,
	GroupScaledScoreReportWithProvider,
	OrganizationScaledScoreReportWithProvider,
	StudentScaledScoreReportWithProvider,
	StudentBenchmarkReportWithProvider,
	ReadingLevelAssessmentReportWithProvider,
	OrgRLAPerformanceReportWithProvider,
	DistrictStudentTestSettingsModalWithProvider,
	SchoolStudentTestSettingsModalWithProvider,
	HomePageWithProvider,
	EditSchoolYearModalWithProvider,
	EditUsageModalWithProvider,
	EditRestrictAccessModalWithProvider
} from '@imaginelearning/web-ui-reporting';
import {
	GrowthReportWithProvider,
	StudentPrintModalToggleWithProvider,
	DashboardGrowthKpiWithProvider,
	ILEStudentDashboardGrowthReportWithProvider,
	printLoader
} from '@imaginelearning/web-reporting';
import {
	NoteBarTestSetting,
	SchoolYearWarningBanner,
	AwesomeIconButton,
	TooltipButton,
	MovedToolsBanner,
	SearchResultsWithProvider,
	// management
	MovedToolsTooltip,
	PendoServiceWrapper
} from '@imaginelearning/web-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

angular
	.module('react.components', ['react'])
	// usage reports
	.value('GroupUsageReportWithProvider', GroupUsageReportWithProvider)
	.value('OrganizationUsageReportWithProvider', OrganizationUsageReportWithProvider)
	.value('StudentUsageReportWithProvider', StudentUsageReportWithProvider)
	.value('UsageGroupsPredictedEoyWithProvider', UsageGroupsPredictedEoyWithProvider)
	.value('UsageOrganizationsPredictedEoyWithProvider', UsageOrganizationsPredictedEoyWithProvider)
	// growth
	.value('BenchmarkTestGainsGroupLevelWithProvider', BenchmarkTestGainsGroupLevelWithProvider)
	.value('BenchmarkTestGainsReportWithProvider', BenchmarkTestGainsReportWithProvider)
	.value('ReadingLevelAssessmentGainsReportWithProvider', ReadingLevelAssessmentGainsReportWithProvider)
	.value('ReadingLevelAssessmentGroupReportWithProvider', ReadingLevelAssessmentGroupReportWithProvider)
	//new growth report
	.value('GrowthReportWithProvider', GrowthReportWithProvider)
	.value('StudentPrintModalToggleWithProvider', StudentPrintModalToggleWithProvider)
	// dashboard
	.value('StudentDashboardGrowthReport', ILEStudentDashboardGrowthReportWithProvider)
	.value('printLoader', printLoader)
	.value('UsageKpi', UsageKpiWithProvider)
	.value('GrowthKpi', GrowthKpiWithProvider)
	.value('DashboardGrowthKpi', DashboardGrowthKpiWithProvider)
	.value('ProgressKpi', ProgressKpiWithProvider)
	.value('AchievementsKpi', AchievementsKpiWithProvider)
	.value('StudentDashboardReportWithProvider', StudentDashboardReportWithProvider)
	.value('StudentDashboardWithProvider', StudentDashboardWithProvider)
	.value('DashboardGroupTable', DashboardGroupTableWithProvider)
	.value('DashboardGroupsTable', DashboardGroupsTableWithProvider)
	.value('DashboardOrganizationsTable', DashboardOrganizationsTableWithProvider)
	.value('SidePanelWithProvider', SidePanelWithProvider)
	.value('GeneratePDFModal', GeneratePDFModal)
	// playlist
	.value('PlaylistReportWithProvider', PlaylistReportWithProvider)
	.value('PlaylistListReportWithProvider', PlaylistListReportWithProvider)
	// management
	// .value('ManageStudentsApp', ManageStudentsApp)
	.value('EditSchoolYearModalWithProvider', EditSchoolYearModalWithProvider)
	.value('EditUsageModalWithProvider', EditUsageModalWithProvider)
	.value('EditRestrictAccessModalWithProvider', EditRestrictAccessModalWithProvider)
	.value('DistrictStudentTestSettingsModalWithProvider', DistrictStudentTestSettingsModalWithProvider)
	.value('SchoolStudentTestSettingsModalWithProvider', SchoolStudentTestSettingsModalWithProvider)
	// portfolio
	.value('GroupPortfolioWithProvider', GroupPortfolioWithProvider)
	.value('StudentPortfolioWithProvider', StudentPortfolioWithProvider)
	// progress
	.value('KindergartenReadinessWithProvider', KindergartenReadinessWithProvider)
	.value('StudentKindergartenReadinessWithProvider', StudentKindergartenReadinessWithProvider)
	.value('OrganizationProgressReportWithProvider', OrganizationProgressReportNoTabsWithProvider)
	.value('CumulativePerformanceReportWithProvider', CumulativePerformanceReportWithProvider)
	.value('SkillsInventoryReportWithProvider', SkillsInventoryReportWithProvider)
	.value('GroupProgressByLessonReportWithProvider', GroupProgressByLessonReportWithProvider)
	.value('StudentProgressByLessonReportWithProvider', StudentProgressByLessonReportWithProvider)
	.value('AchievementsProgressWithProvider', AchievementsProgressWithProvider)
	.value('GroupAchievementsWithProvider', GroupAchievementsWithProvider)
	.value('SkillsInventoryReportWithProvider', SkillsInventoryReportWithProvider)
	.value('StudentAchievementsReportWithProvider', StudentAchievementsReportWithProvider)
	// growth
	.value('RLAStatusWithProvider', RLAStatusWithProvider)
	.value('BenchmarkTestStatusWithProvider', BenchmarkTestStatusWithProvider)
	.value('OrganizationBenchmarkTestPerformanceReportWithProvider', OrganizationBenchmarkTestPerformanceReportWithProvider)
	.value('GroupScaledScoreReportWithProvider', GroupScaledScoreReportWithProvider)
	.value('OrganizationScaledScoreReportWithProvider', OrganizationScaledScoreReportWithProvider)
	.value('StudentScaledScoreReportWithProvider', StudentScaledScoreReportWithProvider)
	.value('ReadingLevelAssessmentReportWithProvider', ReadingLevelAssessmentReportWithProvider)
	.value('OrgRLAPerformanceReportWithProvider', OrgRLAPerformanceReportWithProvider)
	.value('StudentBenchmarkReportWithProvider', StudentBenchmarkReportWithProvider)
	// management
	.value('MovedToolsTooltip', MovedToolsTooltip)
	// web-ui-react
	.value('NoteBarTestSetting', NoteBarTestSetting)
	.value('SchoolYearWarningBanner', SchoolYearWarningBanner)
	.value('AwesomeIconButton', AwesomeIconButton)
	.value('TooltipButton', TooltipButton)
	.value('FontAwesomeIcon', FontAwesomeIcon)
	.value('MovedToolsBanner', MovedToolsBanner)
	.value('SearchResults', SearchResultsWithProvider)
	.value('PendoServiceWrapper', PendoServiceWrapper)
	// home page
	.value('HomePage', HomePageWithProvider);
